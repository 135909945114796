.nav_tabs{
    width: 100%;
    min-height: 400px;
    background-color: #fff;
    border-radius: 4px;
    position: relative;
}

.nav_tabs ul{
    list-style: none;
}

.nav_tabs ul li{
    float: left;
}

.tab_label{
    display: block;
    width: auto;
    padding: 12px 24px;
    color:#999;
    cursor: pointer;
    text-align: center;
}

.tab_label:hover{
  color:#404040;
}

.tab_label_disable{
  cursor: not-allowed;
}

.tab_label_disable.tab_label:hover{
  color:#999;
}

.nav_tabs .rd_tab {
    display:none;
    position: absolute;
}

.nav_tabs .rd_tab:checked ~ label {
    border-bottom: solid 2px #666;
    color:#404040;
}

.tab-content{
  border-top: solid 1px #ccc;
  background-color: #fff;
  display: none;
  position: absolute;
  left: 0;
  width: 100%;
  min-height: 320px;
  padding: 16px;
  opacity: 0;

}

.rd_tab:checked ~ .tab-content{
    opacity: 1;
    display: block;
    transition: opacity .5s linear;
}
