$sidebar-bg-color: #222D32;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: #adadad;
$highlight-color: #d8d8d8;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;

@import '~react-pro-sidebar/dist/scss/styles.scss';
@import 'components/drawer/pure-drawer.scss';
@import 'components/tabs';
@import 'components/collapse';
@import 'components/modal';

body {
  top:0;
  margin: 0;
  height: 100vh;
  overflow: hidden;
  color: #353535;
  font-family: 'Roboto', sans-serif;
}

#root {
  height: 100vh;
}

.app {
  height: 100vh;
  display: flex;
  position: relative;



  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
    margin-right: 16px;
    padding: 4px 10px;
  }

  .pro-sidebar{
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #222D32;
    }
    ::-webkit-scrollbar-thumb {
      background: var(--color-button);
    }
    ::-webkit-scrollbar-thumb:hover {
      background: var(--color-button-h);
    }
  }

  .pro-sidebar-footer{
    padding: 0;
  }


  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 8px 16px;

    .sidebar-btn {
      padding: 1px 24px;
      border:0;
      border-radius: 40px;
      background: rgba(255, 255, 255, 0.05);
      color: #adadad;
      text-decoration: none;
      text-transform: uppercase;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      transition: background-color 0.2s;
      svg{
        color: #95A5A6;
      }
      span {
        margin-left: 8px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
        background-color: rgba(255, 255, 255, 0.1);
      }
    }


  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 40px;
      padding: 0;
      margin: -2px;
    }
  }

  // main {
  //   padding: 0;
  //   flex-grow: 1;
  //   display: flex;
  //   flex-direction: column;
  //   overflow-y: auto;

  //   header {
  //     height: 64px;;
  //     margin-bottom: 40px;
  //     border-bottom: 1px solid #efefef;
  //     h1 {
  //       display: flex;
  //       align-items: center;
  //       // transform: translateX(-20px);
  //     }
  //   }

  //   footer {
  //     margin-top: auto;
  //     color: #888;
  //     text-align: center;
  //     a {
  //       text-decoration: none;
  //       color: #888;
  //       &:hover {
  //         text-decoration: underline;
  //       }
  //     }
  //   }
  // }
  // .social-bagdes {
  //   margin-top: 10px;
  //   img {
  //     margin: 5px;
  //   }
  // }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  &.rtl {
    direction: rtl;
    text-align: right;

    header {
      h1 {
        transform: translateX(20px);
      }
    }
    .block {
      direction: rtl;
      > span {
        margin-left: 0;
        margin-right: 10px;
      }
    }
    .sidebar-btn-wrapper {
      .sidebar-btn {

        span {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }
  }



  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
  }
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}


.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;
  &.red {
    color: #ffffff;
    background: #d63030;
  }
  &.gray {
    color: #ffffff;
    background: #777777;
  }
  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

