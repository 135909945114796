// Point $show-drawer to one of the variables in _pure-drawer-breakpoints.scss to control which breakpoints drawers should appear.
// $show-drawer: $small-only;

/**************
## Animation ##
**************/

$pure-drawer-animation-duration-slide: 300ms;
// $pure-drawer-animation-duration-reveal: 500ms;
// $pure-drawer-animation-duration-slideAlong: 500ms;
// $pure-drawer-animation-duration-reverseSlide: 500ms;
// $pure-drawer-animation-duration-scaleDown: 500ms;
// $pure-drawer-animation-duration-scaleUp: 500ms;
// $pure-drawer-animation-duration-scaleRotate: 500ms;
// $pure-drawer-animation-duration-fall: 500ms;
// $pure-drawer-animation-duration-push: 500ms;
// $pure-drawer-animation-duration-bounce: 1000ms;
$pure-drawer-animation-duration-fade: 300ms;
// $pure-drawer-animation-duration-flipX: 1000ms !default;
// $pure-drawer-animation-duration-flipY: 1000ms !default;
// $pure-drawer-animation-duration-zoom: 1000ms !default;


/************
## Toggler ##
************/

$toggler-color: #404040;
// $toggler-checked-color: #1fc08e;
$toggler-focus-color: #0c0c0c;

.pure-toggle-label-custom{
  height: 35px;
  width: 35px;
  background: $toggler-color;
  border-radius: 4px;

  padding: 4px;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover{
    background: $toggler-focus-color;
  }

  .clear{
    background: transparent;
  }
}

/***********
## Drawer ##
***********/

$drawer-bgcolor: #fff;

// For 'left' and 'right' positioned drawers.
// $drawer-width-small: 100%;
// $drawer-width-medium: 300px;
$drawer-width-large: 600px;

// For 'top' positioned drawers.
// $drawer-height-small: 100%;
// $drawer-height-medium: 100%;
// $drawer-height-large: 100px;

/***********
## Pusher ##
***********/

$pusher-bgcolor: rgba(0,0,0,0.2);

/*******************
## Pusher Overlay ##
*******************/

$overlay-bgcolor: rgba(0,0,0,0.2);


.pure-drawer[data-position='right']  {
	left: auto;
  right: 0;
  top: 65px;
  height: calc(100vh - 65px);
}

.pure-toggle[data-toggle='left']:checked ~ .pure-overlay[data-overlay='left'],
.pure-toggle[data-toggle='right']:checked ~ .pure-overlay[data-overlay='right'],
.pure-toggle[data-toggle='top']:checked ~ .pure-overlay[data-overlay='top'] {
	width: 100%;
  height: 100%;
  top: 65px;
  height: calc(100vh - 65px);
	opacity: 1;
	z-index: 2;
}
