.collapse {
  ul {
    list-style-type: none;
  }

  label{
    color: #333;
  }

  li {
    margin: 16px 0;
    padding: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

  }
}
    .collapse-input {
      display: none;
    }

    .collapse-content{
      margin-top: 16px;
      max-height: 0;
      min-width: 100%;
      opacity: 0;
      overflow: hidden;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }

    .collapse-input:checked ~ .collapse-content {
      max-height: 100%;
      max-width: 100%;
      opacity: 1;
    }

    .collapse-input + label:before {
        content: '';
        display: inline-block;
        border-right: 2px solid #333;
        border-bottom: 2px solid #333;
        width: 8px; height: 8px;
        transform: rotate(-315deg);
        -webkit-transition:all .5s ease;
        -moz-transition:all .5s ease;
        -o-transition:all .5s ease;
        transition:all .5s ease;
        cursor: pointer;
    }


    .collapse-input:checked + label:before {
      transform: rotate(-135deg);
    }

    input[type=checkbox]:checked + label:before {
        transform: rotate(-135deg);
    }

